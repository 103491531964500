import mixpanel from 'mixpanel-browser';

try {
    const init = () => {
        mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN || '', {
            debug: process.env.NEXT_PUBLIC_ENVIRONMENT === 'devlopment'
        });
    };
    init();
} catch (error) {
    console.log(error);
}

export const identifyUser = (id) => {
    try {
        mixpanel.identify(id);
    } catch (e) { console.error(e); }
};

export const setMixpanelUser = ({
    Name, Email, Role, CompanyName
}) => {
    try {
        mixpanel.people.set({
            name: Name, Email, Role, CompanyName
        });
    } catch (e) { console.error(e); }
};

export const trackEvent = (eventName = '', dataObj = {}) => {
    try {
        mixpanel.track(eventName, dataObj);
    } catch (e) { console.error(e); }
};

export const resetUser = () => {
    try {
        mixpanel.reset();
    } catch (e) { console.error(e); }
};
