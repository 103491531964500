import WithAuth from '@/components/Auth/WithAuth';
import { StatusProvider } from '@/components/context/StatusContext';
import 'regenerator-runtime/runtime';

import { UserProvider } from '@auth0/nextjs-auth0';
import AppLoader from 'atoms/AppLoader';
import axios from 'axios';
import { signOut } from 'firebase/auth';
import { sessionLogout } from 'services/user.service';

import {
    doc,
    onSnapshot
} from 'firebase/firestore';
import flagsmith from 'flagsmith/isomorphic';
import { FlagsmithProvider } from 'flagsmith/react';
import { trackEvent } from 'helper/mixPanel';
import { ErrorBoundary } from 'lib/bugsnag';
import { complianceRoles } from 'lib/constants';
import getPageTitle from 'lib/getPageTitle';
import LogRocket from 'logrocket';
import Head from 'next/head';
import Header from 'organisms/Header';
import { hasPath } from 'ramda';
import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    getAuth,
    isAuthenticated,
    logout,
    removeAuth
} from 'services/auth.service';
import {
    db,
    auth as firebaseAuth,
    setDocs, updateDocs
} from 'services/firebase.service';
import { socket, socketConnect, socketEmit } from 'services/socket';
import '../styles/globals.css';

function isValidJSONString(str) { try { if (!str) return false; JSON.parse(str); return true; } catch (error) { return false; } }

let called = false;
async function renewToken(refreshToken, id) {
    called = true;
    return axios.post(`${process.env.NEXT_PUBLIC_API_URL}/auth/refresh`, { refreshToken, id });
}

function MyApp({
    Component, pageProps, flagsmithState, router
}) {
    const [currentUser, setCurrentUser] = useState(null);
    useEffect(() => {
        setCurrentUser(getAuth());
    }, []);
    try {
        if (process.env.NEXT_PUBLIC_LOGROCKET_APP_ID) {
            LogRocket.init(process.env.NEXT_PUBLIC_LOGROCKET_APP_ID, {
                network: {
                    requestSanitizer: (request) => {
                        if (request.headers['x-access-token']) request.headers['x-access-token'] = '**redacted**';
                        if (isValidJSONString(request.body) && JSON.parse(request.body).password) {
                            const body = JSON.parse(request.body);
                            body.password = '**redacted**';
                            request.body = JSON.stringify(body);
                        }
                        return request;
                    },
                    responseSanitizer: (response) => {
                        if (isValidJSONString(response.body) && hasPath(['entity', 'token'], JSON.parse(response.body))) {
                            const body = JSON.parse(response.body);
                            body.entity.token = '**redacted**';
                            body.entity.fbToken = '**redacted**';
                            body.entity.refreshToken = '**redacted**';
                            response.body = JSON.stringify(body);
                        }
                        return response;
                    }
                }
            });
        }
    } catch (error) {
        console.log(error);
    }

    const logoutFromSession = async () => {
        sessionLogout();
        const auth = getAuth();
        try {
            const userDocRef = doc(db, 'presence', auth?.id);
            await updateDocs(userDocRef, {
                online: false
            });
            await signOut(firebaseAuth);
        } catch (e) { console.log(e); }
        socketEmit('/post/users/sessions/logout', { sid: auth?.sid });
        socket.disconnect();
        logout();
    };

    const onSocketConnected = () => {
        if (['/matchings/[id]', '/matchings', '/dashboard',
            '/irs/[id]', '/indexes/[indexId]', '/currencies/[id]', '/bonds/[bondId]'
        ].some((path) => router.pathname === path)) socketEmit('/post/user-group/:join');
        console.log('socket connected');
    };
    const onSocketDisconnected = (e) => {
        console.log('socket disconnected', e);
    };
    const onConnectError = async (err) => {
        console.log(`connect_error due to ${err.message}`);
        if (err.message === 'invalid token' || err.message === 'session_expire') {
            logoutFromSession();
        }
        socket.io.opts.transports = ['websocket'];
    };

    const onLogout = () => {
        socket.disconnect();
        removeAuth();
        window.location.href = '/api/auth/logout';
    };

    useEffect(() => {
        const onAnyEvent = (name, value) => {
            if (value?.error) {
                if (value.error?.error === 'unauthorized') {
                    logoutFromSession();
                }
            }
        };
        const auth = getAuth();
        if (auth?.id) {
            LogRocket.identify(auth.id, {
                email: auth.email, name: auth.name, companyName: auth.companyName, role: auth.role, sessionId: auth.sessionId
            });
        }
        socket.on('connect', onSocketConnected);
        socket.on('disconnect', onSocketDisconnected);
        socket.on('connect_error', onConnectError);
        socket.prependAny(onAnyEvent);
        socket.on('/post/users/sessions/logout', onLogout);
        socketConnect();
        if (isAuthenticated()) {
            socketEmit('/post/users/sessions/:sessionId', { sessionId: auth?.sessionId });
        }

        return () => {
            socket.off('connect', onSocketConnected);
            socket.off('disconnect', onSocketDisconnected);
            socket.off('connect_error', onConnectError);
            socket.off('/post/users/sessions/logout', onLogout);
            socket.offAny(onAnyEvent);
        };
    }, []);

    useEffect(() => {
        const currentUser = getAuth();
        if (currentUser?.id) {
            if (complianceRoles.includes(currentUser.role)) return;
            const presenceRef = doc(db, 'presence', currentUser.id);

            setDocs(presenceRef, { online: true });

            const unsubscribe = onSnapshot(presenceRef, (snapshot) => {
                const data = snapshot.data();
                if (data && !data.online) {
                    setTimeout(() => {
                        const checkAuth = getAuth();
                        if (checkAuth) {
                            setDocs(presenceRef, { online: true });
                        }
                    }, 1000);
                }
            });

            const handleBeforeUnload = () => {
                setDocs(presenceRef, { online: false });
                unsubscribe();
            };
            window.addEventListener('beforeunload', handleBeforeUnload);
            return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
            };
        }
    }, []);

    useEffect(() => {
        const handleComplete = (url) => {
            let pageName = url.slice(1);
            if (!pageName) {
                pageName = 'index';
            }
            trackEvent('Page View', { 'Page Name': pageName });
            return true;
        };
        const handleError = () => true;

        router.events.on('routeChangeComplete', handleComplete);
        router.events.on('routeChangeError', handleError);

        const originalWindowOpen = window.open;

        window.open = (...args) => {
            const [url, target] = args;
            let pageName = 'New Window';
            if (url) {
                const urlObj = new URL(url, window.location.origin);
                pageName = urlObj.pathname.slice(1) || 'index';
            }
            trackEvent('Page View', { 'Page Name': pageName, Target: target || '_blank' });

            return originalWindowOpen.apply(window, args);
        };

        return () => {
            router.events.off('routeChangeComplete', handleComplete);
            router.events.off('routeChangeError', handleError);
            window.open = originalWindowOpen;
        };
    }, [router.events]);

    return (
        <>
            <ErrorBoundary onError={(event) => {
                const customData = {
                    userId: currentUser?.id,
                    name: currentUser?.name,
                    email: currentUser?.email,
                    role: currentUser?.role
                };
                event.addMetadata('custom', customData);
            }}>
                <Head>
                    <meta
                        name="viewport"
                        content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=0"
                    />
                    <title>
                        {getPageTitle(router.pathname)}
                    </title>
                </Head>
                <FlagsmithProvider flagsmith={flagsmith} serverState={flagsmithState}>
                    <UserProvider>
                        <ToastContainer />
                        <StatusProvider>
                            <WithAuth router={router}>
                                <Header />
                                <AppLoader path={router.pathname} />
                                <Component {...pageProps} />
                            </WithAuth>
                        </StatusProvider>
                    </UserProvider>
                </FlagsmithProvider>
            </ErrorBoundary>
        </>
    );
}

MyApp.getInitialProps = async () => {
    await flagsmith.init({
        environmentID: process.env.NEXT_PUBLIC_FLAGSMITH_KEY
    });
    return { flagsmithState: flagsmith.getState() };
};

export default MyApp;
